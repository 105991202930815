import { ReactNode } from "react";

export default function IllustrationBody({
  children,
  src,
  alt,
}: {
  children: ReactNode;
  src: string;
  alt: string;
}) {
  return (
    <div
      style={{
        backgroundColor: "#fffbf2",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "80vw", maxWidth: 350 }}>
        <img
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: "70vw",
            maxHeight: 250,
            width: "70vw",
            maxWidth: 250,
          }}
          src={src}
          alt={alt}
        />
        <div style={{ height: 30 }} />
        {children}
      </div>
    </div>
  );
}
