import welcome from "../../images/welcome.png";
import IllustrationBody from "./IllustrationBody";

export default function ResetSuccessful() {
  return (
    <IllustrationBody src={welcome} alt="welcome">
      <p style={{ textAlign: "center", lineHeight: 1.6, fontSize: 18 }}>
        Your password has been successfully reset
      </p>
    </IllustrationBody>
  );
}
