import { TypeAnimation } from "react-type-animation";
import { appleUrl, googleUrl } from "../const/urls";
import AppStore from "../svgs/app-store.svg";
import GooglePlay from "../svgs/google-play.svg";

export default function Home() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          justifyContent: "flex-end",
          padding: 15,
        }}
      >
        <InstallButton svg={GooglePlay} link={googleUrl} />
        <InstallButton svg={AppStore} link={appleUrl} />
      </div>
      <div style={{ paddingLeft: 36, paddingRight: 36 }}>
        <TypeAnimation
          sequence={[
            "Fivson here",
            1000,
            "Got time but no plan?",
            1000,
            "We've got you covered",
            1000,
            "Fivson makes it easy to create, find, and join events",
            1000,
            "Just let your imagination run wild",
            1000,
            "You can even message or create groups to invite your friends",
            1000,
          ]}
          speed={50}
          style={{ fontSize: "2em", lineHeight: "2em" }}
          repeat={Infinity}
        />
      </div>
    </div>
  );
}

function InstallButton({ svg, link }: { svg: string; link: string }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <button
        style={{
          width: "35vw",
          maxWidth: 200,
          background: "none",
          border: "none",
        }}
      >
        <img width="100%" src={svg} alt="App Store" />
      </button>
    </a>
  );
}
