import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

interface ResetSlice {
  password: string;
  confirmPassword: string;
}

const initialState: ResetSlice = {
  password: "",
  confirmPassword: "",
};

export const selectPassword = (state: RootState) => state.reset.password;

export const selectConfirmPassword = (state: RootState) =>
  state.reset.confirmPassword;

export const selectCanSubmit = (state: RootState) => {
  const { password, confirmPassword } = state.reset;
  return password.length >= 8 && password === confirmPassword;
};

export const resetSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    changePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    changeConfirmPassword: (state, action: PayloadAction<string>) => {
      state.confirmPassword = action.payload;
    },
  },
});

export const { changePassword, changeConfirmPassword } = resetSlice.actions;

export default resetSlice.reducer;
