import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import apiUrl from "../../apiUrl";
import noListFound from "../../images/no_list_found.png";
import postStand from "../../images/post_stand.png";
import { useSelector } from "../../redux/hooks";
import store, { dispatch } from "../../redux/store";
import IllustrationBody from "./IllustrationBody";
import {
  changeConfirmPassword,
  changePassword,
  selectCanSubmit,
  selectConfirmPassword,
  selectPassword,
} from "./resetSlice";

function KeyError() {
  return (
    <IllustrationBody src={noListFound} alt="No list found">
      <p style={{ textAlign: "center", lineHeight: 1.6, fontSize: 18 }}>
        The link you followed is invalid or expired
      </p>
    </IllustrationBody>
  );
}

function PasswordField({
  placeholder,
  value,
  onChange,
}: {
  placeholder: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <OutlinedInput
      id={`outlined-basic-${placeholder}`}
      placeholder={placeholder}
      size="small"
      type={showPassword ? "text" : "password"}
      inputProps={{ style: { fontSize: 15 } }}
      fullWidth
      value={value}
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
}

function PasswordForm({ token }: { token: string }) {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <IllustrationBody src={postStand} alt="Post Stand">
      <p style={{ textAlign: "center", lineHeight: 1.6, fontSize: 18 }}>
        Please enter your new password
      </p>
      <div style={{ height: 30 }} />
      <PasswordField
        placeholder="Password (8+ characters)"
        value={useSelector(selectPassword)}
        onChange={(e) => {
          dispatch(changePassword(e.target.value));
        }}
      />
      <div style={{ height: 15 }} />
      <PasswordField
        placeholder="Confirm password"
        value={useSelector(selectConfirmPassword)}
        onChange={(e) => {
          dispatch(changeConfirmPassword(e.target.value));
        }}
      />
      <div style={{ height: 30 }} />
      <Button
        variant="contained"
        size="medium"
        fullWidth
        disabled={!useSelector(selectCanSubmit)}
        onClick={async () => {
          const password = store.getState().reset.password;

          const res = await fetch(`${apiUrl}/auth/reset-password`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token, password }),
          });

          if (res.ok) {
            navigate("/reset-password/success", { replace: true });
          } else {
            setOpen(true);
          }
        }}
      >
        Submit
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="An error occurred, please try again later."
        action={action}
      />
    </IllustrationBody>
  );
}

export default function ResetPassword() {
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  return token == null ? <KeyError /> : <PasswordForm token={token} />;
}
