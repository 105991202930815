import { useEffect } from "react";
import { appleUrl, googleUrl } from "../const/urls";

declare global {
  interface Window {
    opera: any;
    MSStream: any;
  }
}

export default function Install() {
  useEffect(() => {
    var userAgent = navigator.userAgent || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = googleUrl;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = appleUrl;
    }
  });

  return <div />;
}
