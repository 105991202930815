export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "2em",
        fontWeight: "600",
      }}
    >
      404 Not Found
    </div>
  );
}
